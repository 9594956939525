import router from "@/router";
import { Model } from "@vuex-orm/core";
import { db } from "@/firebase/init";
import {
  doc,
  // collection,
  getDoc,
  // getDocs,
  // Timestamp,
  // serverTimestamp,
  setDoc,
  // addDoc,
  // deleteDoc,
} from "firebase/firestore";
import Exercise from "@/models/Exercise";
import Log from "@/models/Log";

const dayjs = require("dayjs");

export default class User extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "users";

  static state() {
    return {
      currentUserID: undefined,
    };
  }

  static async beforeUpdate(model) {
    const userRef = doc(db, this.entity, model.id);
    if (!userRef) return false;
    return setDoc(userRef, { ...model }, { merge: true });
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      email: this.attr(""),
      isAdmin: this.boolean(false),
      isCurrentUser: this.boolean(false),
      settings: this.attr({}),
    };
  }

  static async login({ uid }) {
    const result = await getDoc(doc(db, this.entity, uid));

    const data = result.exists ? await result.data() : null;

    await this.insert({ data: { id: uid, isCurrentUser: true, ...data } });
    Exercise.load();
    Log.load(uid);
    return this.commit((state) => {
      state.currentUserID = uid;
    });
  }

  /**
   * Callback fired when user logout
   */
  static async logout() {
    this.commit((state) => {
      state.currentUserID = undefined;
    });
    Exercise.deleteAll();
    Log.deleteAll();

    const currentRouter = router.app.$route;
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push("/login");
    }
  }
}

export const UserExtra = {
  getters: {
    currentUser: () => User.query().where("isCurrentUser", true).first(),
    /**
     * level1_start 2021-11-01
     * level2_start 2022-03-20
     * level3_start
     * returns current level based on Setttings "Level Start Date" value
     */
    currentLevel(_, getters) {
      let currentLevel = 0;
      if (!getters.currentUser) return currentLevel;

      const settings = getters.currentUser.settings;
      Object.entries(settings).forEach(([key, value]) => {
        const found = RegExp("^level(.*)_start$", "g").exec(key);
        const daysPast = dayjs().diff(dayjs(value), "day");
        if (found && value && daysPast >= 0 && daysPast < 365) {
          [, currentLevel] = found;
        }
      });
      return parseInt(currentLevel);
    },
    currentDay(_, getters) {
      const date = dayjs(getters.currentLevelStartDate).format("YYYY-MM-DD");
      return dayjs().diff(date, "day");
    },
    currentLevelStartDate(_, getters) {
      const level = getters.currentLevel;
      if (!level) return "";
      return getters.currentUser
        ? getters.currentUser.settings[`level${level}_start`]
        : "";
    },
  },
};
