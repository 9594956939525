<template lang="pug">
  v-snackbar(:value="updateExists" timeout="-1" )
    span(v-if="refreshing") Updating..
    span(v-else) Update available.
    template(v-slot:action="{ attrs }")
      v-btn(text v-bind="attrs" @click="refreshApp" :loading="refreshing") Refresh
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (!this.refreshing) return;
      this.refreshing = false;
      this.updateExists = false;
      window.location.reload();
    });
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    async refreshApp() {
      this.refreshing = true;
      if (!this.registration || !this.registration.waiting) return;
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
};
</script>
