import router from "@/router";
import { isNil } from "lodash";
import { createNewUserFromFirebaseAuthUser } from "@/misc/helpers";
import UsersDB from "@/firebase/users-db";

export default {
  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, firebaseAuthUser) => {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid);

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase;

    commit("setUser", user);
    // dispatch("products/getUserProducts", null, { root: true });
    console.log(">>>>   1", 1);
    dispatch("exercises/getExercises", null, { root: true });
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit }) => {
    commit("setUser", null);
    // commit('products/setProducts', null, { root: true })

    const currentRouter = router.app.$route;
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push("/login");
    }
  },

  updateUserSettings: async ({ commit, rootState }, settings) => {
    try {
      const userDB = await new UsersDB();
      await userDB.update({
        id: rootState.authentication.user.id,
        settings,
      });
      return commit("updateUserSettings", settings);
    } catch (error) {
      console.log(">>>>   error", error);
      return error;
    }
  },
};
