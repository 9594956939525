import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4vrllhRIFMopPfqfY8FdjpyOuuYNIiek",
  authDomain: "workout-51b40.firebaseapp.com",
  databaseURL: "https://workout-51b40-default-rtdb.firebaseio.com",
  projectId: "workout-51b40",
  storageBucket: "workout-51b40.appspot.com",
  messagingSenderId: "736951783151",
  appId: "1:736951783151:web:af582f4da8e59c4e40151a",
  measurementId: "G-3YV4DS90YC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
const messaging = getMessaging(app);

export { app, storage, auth, db, messaging };
