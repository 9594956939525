<template>
  <div v-if="!finished" style="width: 100%">
    <p
      v-if="started"
      transition="scroll-x-transition"
      min-height="82"
      class="green--text text--darken-4- text-center text-uppercase headline- display-2 my-0 py-4 font-weight-bold- font-weight-thin-"
    >
      <!-- <span class="font-weight-thin grey--text">rest</span>  -->
      {{ formatSimpleTime(display) }}
    </p>

    <v-btn
      v-else
      x-large
      block
      min-height="82"
      ripple
      transition="scroll-x-transition"
      color="green darken-1"
      @click="start"
    >
      {{ formatSimpleTime(display) }} rest
    </v-btn>
  </div>
</template>

<script>
import { longBeep } from "@/beep";

export default {
  props: {
    countdown: {
      type: Number,
      default: 30,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    started: false,
    finished: false,
    time: 0,
  }),
  computed: {
    display() {
      let { time } = this;
      if (!this.started && !this.finished) {
        time = this.countdown;
      }
      return time;
    },
  },
  async mounted() {
    this.keyListener = (e) => {
      if (e.key === "n" || e.code === "Space") {
        e.preventDefault();
        this.start();
      }
    };
    document.addEventListener("keydown", this.keyListener.bind(this));
  },

  methods: {
    start() {
      this.time = this.countdown;
      this.started = true;
      const intervalID = setInterval(() => {
        this.time -= 1;

        // if ([3, 2, 1].includes(this.time)) {
        //   beep()
        // }
        if (this.time <= 0) {
          longBeep();
          this.finished = true;
          this.started = false;
          this.callback();
          clearInterval(intervalID);
        }
      }, 1000);
    },
  },
};
</script>
