import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/misc/register-service-worker";
import "@/misc/handle-network-status";
import "@/firebase/init";
import "@/firebase/authentication";
import "@/misc/handle-apple-install-prompt";
// import 'pwacompat'
import vuetify from "@/plugins/vuetify";
import dateMixin from "./mixins/dateMixin";
import appUpdate from "./mixins/app-update";

Vue.mixin(dateMixin);
Vue.mixin(appUpdate);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
