<template>
  <div class="d-flex justify-center" style="width: 100%">
    <v-progress-circular
      v-if="progress"
      indeterminate
      size="82"
      :value="time"
      :width="2"
      color="green darken-1"
      >{{ time }}</v-progress-circular
    >
    <v-btn
      v-if="!progress && !finished"
      min-height="82"
      x-large
      block
      ripple
      transition="slide-x-transition"
      color="green-s accent-4-"
      @click="start"
      @keydown="start"
    >
      Start
    </v-btn>
  </div>
</template>

<script>
import { beep, longBeep } from "@/beep";

export default {
  props: {
    limit: {
      type: Number,
      default: 0,
    },
    beepTime: {
      type: Number,
      default: 0,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    progress: false,
    finished: false,
    time: 0,
    defaultTime: 40,
  }),
  async mounted() {
    this.keyListener = (e) => {
      if (e.key === "n" || e.code === "Space") {
        e.preventDefault();
        this.start();
      }
    };
    document.addEventListener("keydown", this.keyListener.bind(this));
  },
  methods: {
    start() {
      this.progress = true;
      this.finished = false;
      this.time = 0;

      const intervalID = setInterval(() => {
        this.time += 1;

        const beepSequence = [
          this.beepTime - 3,
          this.beepTime - 2,
          this.beepTime - 1,
        ];
        if (this.beepTime && beepSequence.includes(this.time)) {
          beep();
        }

        if (this.time >= (this.limit || this.defaultTime)) {
          if (this.beepTime) longBeep();
          this.progress = false;
          this.finished = true;
          clearInterval(intervalID);
          this.done();
        }
      }, 1000);
    },
    done() {
      this.callback();
      this.progress = false;
      this.finished = false;
      this.time = 0;
    },
  },
};
</script>
