import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import User, { UserExtra } from "@/models/User";
import Exercise, { ExerciseExtra } from "@/models/Exercise";
import Log from "@/models/Log";

// TODO: remove legacy
import authentication from "./authentication";
import app from "./app";
// import exercises from "./exercises";

Vue.use(Vuex);

// Create a new instance of Database.
const database = new VuexORM.Database();

// Register Models to Database.
// TODO: Add App to depricate legacy store
database.register(User, UserExtra);
database.register(Exercise, ExerciseExtra);
database.register(Log);

/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  plugins: [VuexORM.install(database)],
  modules: {
    authentication,
    app,
    // exercises,
  },
});
