import { getAuth, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import User from "@/models/User";
import router from "@/router";

const auth = getAuth();

onAuthStateChanged(auth, async (firebaseUser) => {
  if (firebaseUser) {
    User.login(firebaseUser);
  } else {
    // User.logout();
    router.push("/login");
  }
});

getRedirectResult(auth)
  .then((result) => {
    if (result && result.credential) {
      /** @type {firebase.auth.OAuthCredential} */
      // const { credential } = result
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const token = credential.accessToken
      // ...
    }
    // The signed-in user info.
    const firebaseUser = result && result.user;

    if (firebaseUser) {
      User.login(firebaseUser);
    } else {
      User.logout();
    }
  })
  .catch((error) => {
    console.log(">>>>  error", error);
  });
