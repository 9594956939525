import { isNil, keys, cloneDeep } from "lodash";
// import firestore from "./async-firestore";
import { db } from "@/firebase/init";
import {
  doc,
  collection,
  getDoc,
  getDocs,
  Timestamp,
  serverTimestamp,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

export default class GenericDB {
  constructor(collectionPath) {
    this.collectionPath = collectionPath;
  }

  /**
   * Create a document in the collection
   * @param data
   * @param id
   */
  async create(data) {
    const dataToCreate = {
      ...data,
      createTimestamp: serverTimestamp(),
      updateTimestamp: serverTimestamp(),
    };

    const doc = await addDoc(doc(db, this.collectionPath), {
      ...dataToCreate,
      updateTimestamp: serverTimestamp(),
    });

    return {
      id: doc.id,
      ...data,
      createTimestamp: new Date(),
      updateTimestamp: new Date(),
    };
  }

  /**
   * Read a document in the collection
   * @param id
   */
  async read(id) {
    const result = await getDoc(doc(db, this.collectionPath, id));

    const data = result.exists ? result.data() : null;

    if (isNil(data)) return null;

    this.convertObjectTimestampPropertiesToDate(data);
    return { id, ...data };
  }

  /**
   * Read all documents in the collection following constraints
   * @param constraints
   */
  async readAll(constraints = null) {
    const collectionRef = collection(db, this.collectionPath);

    let query = collectionRef;

    if (constraints) {
      constraints.forEach((constraint) => {
        query = query.where(...constraint);
      });
    }

    const formatResult = (result) => {
      return result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        })
      );
    };

    return formatResult(await getDocs(query));
  }

  /**
   * Update a document in the collection
   * @param data
   */
  async update(data) {
    const { id } = data;
    const clonedData = cloneDeep(data);
    delete clonedData.id;

    await setDoc(
      doc(db, this.collectionPath, id),
      {
        ...clonedData,
        updateTimestamp: serverTimestamp(),
      },
      { merge: true }
    );

    return id;
  }

  /**
   * Delete a document in the collection
   * @param id
   */
  async delete(id) {
    return await deleteDoc(doc(db, this.collectionPath, id));
  }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  convertObjectTimestampPropertiesToDate(obj) {
    const newObj = {};

    keys(obj)
      .filter((prop) => obj[prop] instanceof Object)
      .forEach((prop) => {
        if (obj[prop] instanceof Timestamp) {
          newObj[prop] = obj[prop].toDate();
        } else {
          this.convertObjectTimestampPropertiesToDate(obj[prop]);
        }
      });

    return {
      ...obj,
      ...newObj,
    };
  }
}
