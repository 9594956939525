<template>
  <v-app-bar
    :class="{ transparent: true, offline: !networkOnLine, navbar: true }"
    flat
    app
  >
    <v-toolbar-title>
      <span class="site-name grey--text text--darken-1 title-desktop"
        >practice</span
      >
      <!-- <span
        v-if="level"
        class="site-info-plus grey--text text--darken-3 title-desktop"
        >/ level {{ level }}</span
      > -->
      <span
        v-if="day"
        class="site-info-plus grey--text text--darken-3 title-desktop"
        >/ day {{ day }}</span
      >

      <span class="site-name title-mobile">{{ appShortTitle }}</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- <v-btn
      v-if="!edit && user && user.isAdmin"
      icon-
      plain
      @click="edit = true"
    >
      <span v-if="level" class="pr-5">Level {{ level }}</span>
      <v-icon left>mdi-calendar</v-icon>
      <span v-if="day">Day {{ day }}</span>
    </v-btn>
    <v-text-field
      v-if="edit"
      :value="level"
      placeholder="Level"
      class="day--input"
      hide-details
      autofocus-
      single-line
      :width="30"
      type="number"
      solo
      @keyup.enter="onEnterLevel"
    ></v-text-field>
    <v-text-field
      v-if="edit && user && user.isAdmin"
      :value="day"
      placeholder="Day"
      class="day--input"
      hide-details
      autofocus
      single-line
      :width="50"
      type="number"
      solo
      @keyup.enter="onEnterDay"
    ></v-text-field> -->

    <!-- <v-btn v-if="user && user.isAdmin" :to="{ name: 'calendar' }" icon>
      <v-icon color="grey darken-1">mdi-calendar</v-icon>
    </v-btn> -->

    <v-menu v-if="user" top close-on-content-click min-width="200">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="grey darken-1" icon dark v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item :to="{ name: 'home' }">
          <v-list-item-title>Practice</v-list-item-title>
        </v-list-item>

        <div v-if="user && user.isAdmin">
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'calendar' }">
            <!-- <v-icon color="grey darken-1">mdi-calendar</v-icon> -->
            <v-list-item-title>Calendar</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader>Workouts</v-subheader>
          <v-list-item :to="{ name: 'day', params: { level: '1' } }">
            <v-list-item-title>Level 1</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'day', params: { level: '2' } }">
            <v-list-item-title>Level 2</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'day', params: { level: '3' } }">
            <v-list-item-title>Level 3</v-list-item-title>
          </v-list-item>

          <!-- <v-subheader></v-subheader> -->
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'day', params: { level: '3' } }">
            <v-list-item-title>Ashtanga</v-list-item-title>
          </v-list-item>
          <!-- <v-divider></v-divider>
          <v-list-item :to="{ name: 'calendar' }">
            <v-list-item-title>Calendar</v-list-item-title>
          </v-list-item> -->
        </div>
        <v-divider></v-divider>
        <v-list-item :to="{ name: 'settings' }">
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  <!-- <header class="navbar" >
    <router-link to="/home">
      <img alt="logo-bento" class="logo" src="@/assets/img/bento-starter.svg" />
      <span class="site-name title-desktop">{{ appTitle }}</span>
      <span class="site-name title-mobile">{{ appShortTitle }}</span>
    </router-link>
    <div class="links">
      <nav class="nav-links">
        <div class="nav-item">
          <router-link to="/workouts">Workouts</router-link>
        </div>
        <div class="nav-item">
          <router-link to="/products">Products</router-link>
        </div>
        <div v-if="!isUserLoggedIn && networkOnLine" class="nav-item">
          <router-link to="/login">Login</router-link>
        </div>
        <div
          v-if="isUserLoggedIn && networkOnLine"
          class="nav-item logout-item"
          @click="logout"
        >
          <a>Logout</a>
        </div>
        <div v-if="!networkOnLine" class="nav-item offline-label">Offline</div>
      </nav>

      <img
        v-if="isUserLoggedIn && networkOnLine"
        class="user-picture can-hide"
        :src="user.photoURL"
        alt="Avatar"
      />
    </div>
  </header> -->
</template>

<script>
import { getAuth } from "firebase/auth";
import { mapState } from "vuex";
import User from "@/models/User";

export default {
  data() {
    return {
      edit: false,
      // level: null,
      // day: null,
    };
  },
  computed: {
    ...mapState("app", ["networkOnLine", "appTitle", "appShortTitle"]),
    user() {
      return User.getters("currentUser");
    },
    level() {
      return User.getters("currentLevel");
    },
    day() {
      return User.getters("currentDay");
    },
  },
  watch: {
    $route(to) {
      if (["workouts", "preview"].includes(to.name)) {
        // this.level = this.$router.currentRoute.params.level;
        // this.day = this.$router.currentRoute.params.day;
      }
    },
  },
  created() {
    // if (this.$router.currentRoute.params) {
    //   this.level = parseInt(this.$router.currentRoute.params.level, 10)
    //   this.day = parseInt(this.$router.currentRoute.params.day, 10)
    //   console.log('>>>>   this.level', this.level)
    // }
  },
  methods: {
    async logout() {
      await getAuth().signOut();
    },
    onEnterLevel(e) {
      this.edit = false;
      return this.$router.push({
        name: "workouts",
        params: { level: e.target.value, day: this.day },
      });
    },
    onEnterDay(e) {
      this.edit = false;
      return this.$router.push({
        name: "workouts",
        params: { level: this.level, day: e.target.value },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";
// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap');

.navbar {
  .day--input {
    width: 110px;
    max-width: 110px;
  }
  // position: absolute;
  // top: 0;
  // left: 0;
  // z-index: 20;
  // right: 0;
  // height: $navbar-height;
  // background-color: $navbar-color;
  // box-sizing: border-box;
  // border-bottom: 1px solid #eaecef;
  // padding: 0.7rem 1.5rem;
  // line-height: 2.2rem;

  a {
    display: flex;
    align-items: center;
  }

  .title-desktop {
    display: inline;
  }

  .title-mobile {
    display: none;
  }

  // @media (max-width: 500px) {
  //   padding: 0.7rem 0.7rem;

  //   .can-hide {
  //     display: none;
  //   }

  //   .title-desktop {
  //     display: none;
  //   }

  //   .title-mobile {
  //     display: block;
  //   }
  // }

  .site-name,
  .site-info,
  .site-info-plus {
    font-size: 1.2rem;
    font-weight: 300;
    font-family: "Comfortaa", cursive;
    // color: #555;
    // position: relative;
  }
  .site-info-plus {
    font-size: 1rem;
  }

  .logo {
    height: 24px;
    padding-right: 8px;
  }

  .links {
    padding-left: 1.5rem;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 0.9rem;
    position: absolute;
    right: 1.5rem;
    top: 0.7rem;
    display: flex;

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: center;

      .nav-item {
        position: relative;
        display: inline-block;
        margin-left: 1.5rem;
        line-height: 2.2rem;

        &:first-child {
          margin-left: 0;
        }

        a {
          font-weight: 500;
          font-size: 0.9rem;
          text-decoration: none;
          color: $navbar-link-color;
          border-color: #2c3e50;
          line-height: 1.4rem;
          display: inline-block;
          cursor: pointer;
        }

        @mixin activatedLink() {
          margin-bottom: -2px;
          border-bottom: 2px solid $vue-color;
        }

        .router-link-active {
          @include activatedLink;
        }

        @media (hover) {
          :hover {
            @include activatedLink;
          }
        }
      }
    }
  }

  &.offline {
    background: $navbar-offline-color;
    .links .nav-links .nav-item a,
    .site-name {
      color: white;
    }
  }

  .user-picture {
    max-height: 32px;
    margin-left: 1.5rem;
    border-radius: 50%;
  }

  .offline-label {
    padding: 0px 10px;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    margin-left: 1.5rem;
  }
}
</style>
