let audio = null

function initAudio() {
  if (!audio) audio = new AudioContext()
  return audio
}

// Default: 100, 490, 100
function beep(vol = 100, freq = 490, duration = 100) {
  initAudio()
  if (!audio) return
  const v = audio.createOscillator()
  const u = audio.createGain()
  v.connect(u)
  v.frequency.value = freq
  v.type = 'square'
  u.connect(audio.destination)
  u.gain.value = vol * 0.01
  v.start(audio.currentTime)
  v.stop(audio.currentTime + duration * 0.001)
}

function longBeep() {
  beep(100, 490, 300)
}

export { beep, longBeep }
