import Vue from "vue";
import Router from "vue-router";
import Head from "vue-head";
import Home from "@/views/Home";
import CheckLogin from "@/views/CheckLogin";
import User from "@/models/User";

Vue.use(Router);

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE,
});

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/check-login",
      name: "check-login",
      component: CheckLogin,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-login" */ "@/views/Login.vue"
        ),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: "/level/:level/day/:day",
      name: "workouts",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ "@/views/Workouts.vue"
        ),
    },
    {
      path: "/level/:level/day/:day/preview",
      name: "preview",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ "@/views/Preview.vue"
        ),
    },
    {
      path: "/level/:level/today",
      name: "day",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ "@/views/WorkoutDay.vue"
        ),
    },
    {
      path: "/calendar",
      name: "calendar",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ "@/views/Calendar.vue"
        ),
    },
    {
      path: "/settings",
      name: "settings",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-products" */ "@/views/Settings.vue"
        ),
    },
    { path: "*", redirect: "/" },
  ],
});

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!User.getters("currentUser");
  if (!(to.meta && to.meta.authNotRequired) && !isLoggedIn) {
    const path = !isLoggedIn ? "/login" : "/check-login";
    return next(`${path}?redirectUrl=${to.path}`);
  }
  next();
});

export default router;
