<template lang="pug">
  WorkoutDay(:allow-edit="false" :level="level" v-if="user")
</template>

<script>
import WorkoutDay from "@/views/WorkoutDay.vue";
import User from "@/models/User";

export default {
  name: "Home",

  components: {
    WorkoutDay,
  },
  computed: {
    user: () => User.getters("currentUser"),
    level: () => User.getters("currentLevel"),
  },
};
</script>
