import { register } from "register-service-worker";

// import store from "@/store";

if (process.env.NODE_ENV === "production") {
  register("/service-worker.js", {
    ready() {
      // eslint-disable-next-line no-console
      console.info("Service worker is active.");
    },
    registered() {
      // eslint-disable-next-line no-console
      console.info("Service worker has been registered.");
    },
    cached() {
      // eslint-disable-next-line no-console
      console.info("Content has been cached for offline use.");
    },
    updatefound() {
      // eslint-disable-next-line no-console
      console.info("New content is downloading.");
    },
    updated(registration) {
      // eslint-disable-next-line no-console
      console.info("New content is available; please refresh.");
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      // eslint-disable-next-line no-console
      console.info(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      // eslint-disable-next-line no-console
      console.error("Error during service worker registration:", error);
    },
  });
}
