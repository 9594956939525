<template>
  <v-container class="py-0 fill-height pb-16 day" fluid>
    <div
      v-if="!isFinished && isWorkoutDay"
      class="d-flex flex-column align-center justify-center mx-auto"
      style="width: 100%"
    >
      <exercise-view
        v-if="currentExercise"
        :exercise="currentExercise"
        :done="isDone(currentExercise.num)"
        :exercise-progress="exerciseProgress"
        :rest-mode="showRestTimer"
      />
      <v-card
        class="my-0 transparent mt-0"
        style="width: 100%"
        flat
        max-width="500"
      >
        <v-progress-linear
          :buffer-value="progress + progressSingle"
          :value="progress"
          rounded
          :stream="false"
          height="2"
          color="grey"
          class="mt-6"
        ></v-progress-linear>
        <div
          class="d-flex my-2 mb-6 flex-column align-end grey--text text--darken-3 justify-start"
        >
          {{ workoutProgress }}
        </div>

        <!-- for rest time -->
        <rest-timer
          v-if="showRestTimer"
          :countdown="restTime"
          :callback="restTimeEnded"
        />
        <!-- for workout time -->
        <workout-timer
          v-if="showWorkoutTimer"
          :limit="workoutTime"
          :beep-time="workoutTime"
          :callback="workoutTimeEnded"
        />
        <!-- <v-btn @click="logWorkout">log</v-btn> -->
      </v-card>
    </div>
    <div
      v-if="isFinished && isWorkoutDay"
      class="d-flex flex-column align-center justify-center mx-auto"
      style="width: 100%"
    >
      <div
        class="d-flex pt-14 flex-column align-center grey--text justify-center"
      >
        <v-icon class="green--text" x-large>mdi-check</v-icon>
        <div class="pt-4 text-h5">Done for today!</div>
        <!-- <div class="pt-4 text-h5">
          <v-btn small @click="reset"> reset </v-btn>
        </div> -->

        <div class="pt-4 text-h5">
          <v-btn small @click="subscribeToNotifications"> get notified </v-btn>
          {{ tkn }}
        </div>
      </div>
    </div>
    <div
      v-if="!isFinished && !isWorkoutDay"
      class="d-flex flex-column align-center justify-center mx-auto"
      style="width: 100%"
    >
      <div class="d-flex flex-column align-center grey--text justify-center">
        <v-icon class="grey--text text--darken-3" size="64"
          >mdi-calendar</v-icon
        >
        <div class="pt-4 text-h4">rest day!</div>
        <div class="pt-4 text-caption grey--text text--darken-2">
          level {{ level }} - day {{ day }}
        </div>
      </div>
    </div>
    <v-fab-transition v-if="user.isAdmin && allowEdit">
      <v-btn
        color="green"
        class="mb-10"
        fab
        dark
        absolute
        fixed
        bottom
        right
        @click="editDay"
      >
        <v-icon>mdi-pen</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import ExerciseView from "@/components/Exercise";
import RestTimer from "@/components/RestTimer";
import WorkoutTimer from "@/components/WorkoutTimer";
import User from "@/models/User";
import Exercise from "@/models/Exercise";
import Log from "@/models/Log";
import { onMessage, getToken, getMessaging } from "firebase/messaging";
import { messaging } from "@/firebase/init";

// const dayjs = require("dayjs");

export default {
  components: { ExerciseView, RestTimer, WorkoutTimer },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      width: 300,
      showRestTimer: false,
      showWorkoutTimer: true,
      wakeLock: null,
      tkn: null,
    };
  },
  async mounted() {
    if ("wakeLock" in navigator) {
      try {
        this.wakeLock = await navigator.wakeLock.request("screen");
        console.log("Wake Lock is active!");
      } catch (err) {
        console.log(">>>>   err", err);
      }
    }

    const messaging1 = getMessaging();

    onMessage(messaging1, (payload) => {
      console.log(">>>> Message on clinet  payload", payload);
    });
  },
  beforeDestroy() {
    this.releaseWakeLock();
  },
  watch: {
    isFinished(value) {
      if (value) {
        console.log("Workout Done!");
        this.releaseWakeLock();
      }
    },
  },
  computed: {
    ...mapState("app", ["networkOnLine"]),
    // ...mapState('authentication', ['user']),
    user() {
      return User.getters("currentUser");
    },
    settings() {
      return User.getters("currentUser").settings;
    },
    startDate() {
      return User.getters("currentLevelStartDate");
    },
    day() {
      return User.getters("currentDay");
    },
    exercises() {
      return Exercise.getters("todaysExercises")(this.level, this.day);
    },
    isWorkoutDay() {
      return !!this.exercises.length;
    },
    logs() {
      return Log.query()
        .where("level", this.level)
        .where("day", this.day)
        .get();
    },
    done() {
      return this.exercises.filter((exercise) => {
        return this.isDone(exercise.num);
      });
    },
    isFinished() {
      return this.done.length && !this.upcoming.length;
    },
    upcoming() {
      return this.exercises.filter((exercise) => {
        return !this.isDone(exercise.num);
      });
    },
    currentExercise() {
      return this.upcoming ? this.upcoming[0] : {};
    },
    currentExerciseNumber() {
      return this.done.length + 1;
    },
    totalReps() {
      return this.exercises.length;
    },
    progress() {
      return (
        Math.round((100 * this.currentExerciseNumber) / this.totalReps) -
        Math.round(100 / this.totalReps)
      );
    },
    progressSingle() {
      return Math.round(100 / this.totalReps);
    },
    restTime() {
      try {
        const finishedExercise = this.done.slice(-1)[0];
        if (finishedExercise) {
          return finishedExercise.rest;
        }
      } catch (error) {
        console.log(">>>>   error", error);
      }
      return this.currentExercise ? this.currentExercise.rest : 0;
    },
    workoutTime() {
      if (!this.currentExercise) return;
      switch (this.currentExercise.duration.type) {
        default:
        case "RM":
          return this.currentExercise
            ? this.currentExercise.duration.max * 3 // each rep mulitplied by 3 (in sec)
            : 0;
        case "TM":
          return this.currentExercise ? this.currentExercise.duration.min : 0;
      }
    },
    exerciseProgress() {
      if (!this.currentExercise) return;
      const total = this.exercises.filter(
        (exercise) => exercise.name === this.currentExercise.name
      ).length;
      if (total < 1) return "";
      const current = this.done.filter(
        (exercise) => exercise.name === this.currentExercise.name
      ).length;
      return `${current + 1} of ${total}`;
    },
    workoutProgress() {
      return `${this.currentExerciseNumber} of ${this.totalReps}`;
    },
  },
  methods: {
    releaseWakeLock() {
      if (this.wakeLock) {
        this.wakeLock.release().then(() => {
          console.log("Wake Lock is disabled!");
          this.wakeLock = null;
        });
      }
    },
    isDone(num) {
      return !!this.logs.find((log) => log.num === num);
    },
    workoutTimeEnded() {
      if (this.currentExercise.rest) {
        this.showWorkoutTimer = false;
        this.showRestTimer = true;
      }
      this.logWorkout();
    },
    restTimeEnded() {
      this.showRestTimer = false;
      this.showWorkoutTimer = true;
    },
    logWorkout() {
      Log.insert({
        data: {
          exercise: this.currentExercise.id,
          level: this.currentExercise.level,
          day: this.currentExercise.day,
          num: this.currentExercise.num,
        },
      });
    },
    editDay() {
      this.$router.push({
        name: "workouts",
        params: { level: `${this.level}`, day: `${this.day}` },
      });
    },
    reset() {
      this.logs.forEach((log) => {
        Log.delete(log.id);
      });
    },
    async subscribeToNotifications() {
      Notification.requestPermission()
        .then(async (permission) => {
          if (permission === "granted") {
            try {
              const token = await getToken(messaging, {
                vapidKey:
                  "BHYEZtLhjAT4CJfTxxa2Rdm-ElQ7iJZnCRQ520NyscPo2iyY-dMMHD4_7Fflee19MEllIEdnCEQGY0bbkDDDaPA",
              });
              if (token) {
                this.tkn = `Token: ${token}`;
                console.log(">>>>   token", token);
              } else {
                // request permission
                this.tkn = `Request??`;
              }
            } catch (error) {
              this.tkn = `Error: ${error}`;
              console.log(">>>>   error", error);
            }
          } else {
            this.tkn = `Notification permission declined: ${permission}`;
            console.log("Unable to get permission to notify. ");
          }
        })
        .catch((err) => {
          this.tkn = `Notification permission declined`;
          console.log("Unable to get permission to notify. ", err);
        });
    },
  },
};
</script>

<style lang="scss">
// @import '@/theme/variables.scss';
.v-application {
  .text-h4,
  .text-caption {
    font-family: "Comfortaa", cursive !important;
  }
}
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 28px;
}
</style>
