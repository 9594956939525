<template>
  <div class="d-flex pa-0 flex-no-wrap justify-center" style="width: 100%">
    <v-card class="my-0 transparent my-0" width="100%" flat max-width="500">
      <div
        class="text-h4 font-weight-light d-flex pt-10 flex-no-wrap justify-center"
        style="text-align: center"
      >
        <span v-if="restMode" class="green--text">NEXT</span>
        <span v-else>{{ exercise.title }}</span>

        <v-icon v-if="done" right large color="green darken-2">
          mdi-check
        </v-icon>
      </div>
      <v-card-title
        class="text-h6 font-weight-light grey--text text--darken-2 d-flex pt-2 pb-6 flex-no-wrap justify-center"
      >
        {{ exercise.reps === "x" ? `${exercise.max || 1}x` : "" }}
        {{ exercise.duration.min }}-{{ exercise.duration.max }}
        {{ exercise.duration.type }}
      </v-card-title>
      <div class="d-flex pa-0 flex-no-wrap justify-center">
        <v-avatar
          class="ma-0"
          rounded
          size--="500"
          width="100%"
          height="100%"
          tile
        >
          <v-img
            v-if="exercise.img_url"
            max-height="700"
            max-width="660"
            contain-
            :aspect-ratio="1"
            :src="exercise.img_url"
          >
            <div
              v-if="exerciseProgress"
              class="d-flex flex-column align-content-end flex-wrap"
              style="width: 100%"
            >
              <v-card class="ma-2 px-3 grey--text rounded" outlined tile>
                {{ exerciseProgress }}
              </v-card>
            </div>
            <!-- <div
              v-if="restMode"
              class="d-flex flex-row justify-center flex-wrap"
              style="width: 100%"
            >
              <v-card
                class="ma-3 py-3 transparant-0 font-weight-light rounded"
                outlined
                tile
                width="100%"
                style="opacity: 0.9"
                color="grey darken-4"
              >
                <span class="orange--text- text-h6">NEXT</span>
              </v-card>
            </div> -->
          </v-img>
        </v-avatar>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    exercise: {
      type: Object,
      default: () => {
        return {};
      },
    },
    exerciseProgress: {
      type: String,
      default: null,
    },
    done: {
      type: Boolean,
      default: false,
    },
    restMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCountdown: false,
      showTimer: true,
    };
  },
  computed: {
    countdownDuration() {
      // {{ exercise.duration.min }}-{{ exercise.duration.max }}
      if (this.exercise.duration.type === "RM") {
        return this.exercise.duration.min * 3;
      }
      return this.exercise.duration.min;
    },
    timerLimit() {
      if (this.exercise.duration.type === "RM") {
        return this.exercise.duration.min * 5;
      }
      return this.exercise.duration.min;
    },
    timerBeepLimit() {
      if (this.exercise.duration.type === "TM") {
        return this.exercise.duration.min;
      }
      return 0;
    },
  },
  methods: {
    timerEnded() {
      if (this.exercise.rest) {
        this.showTimer = false;
        this.showCountdown = true;
      }
      this.logWorkout();
    },
    countdownEnded() {},
    logWorkout() {
      this.$store.dispatch("exercises/logExercise", {
        id: this.exercise.id,
        level: this.exercise.level,
        day: this.exercise.day,
        num: this.exercise.num,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/theme/variables.scss';
.title {
  text-transform: uppercase;
}
</style>
