import Vue from 'vue'

export default {
  setUser: (state, value) => (state.user = value),

  updateUserSettings: (state, settings) => {
    Vue.set(state.user, 'settings', {
      ...state.user.settings,
      ...settings
    })
  }
}
