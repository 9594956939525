export default {
  methods: {
    formatSimpleTime(time) {
      if (time) {
        const mins = parseInt(time / 60, 10)
        const secs = parseInt(time, 10) - mins * 60
        return `${mins}:${secs < 10 ? `0${secs}` : secs}`
      }
      return ''
    }
  }
}
