import { Model } from "@vuex-orm/core";
import { db } from "@/firebase/init";
import User from "@/models/User";
import {
  doc,
  collection,
  // getDoc,
  getDocs,
  // Timestamp,
  // serverTimestamp,
  // setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

export default class Log extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "logs";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      day: this.attr(""),
      level: this.attr(""),
      exercise: this.attr(""),
      num: this.attr(""),
    };
  }

  static async load(userId) {
    let data = [];

    const resultSnapshot = await getDocs(
      collection(db, `users/${userId}/logs`)
    );
    resultSnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    await this.insert({ data: data });
  }

  static async beforeCreate(model) {
    const user = User.getters("currentUser");

    if (user && model.id.length === 5) {
      // new log
      const logRef = await addDoc(collection(db, `users/${user.id}/logs`), {
        day: model.day,
        level: model.level,
        exercise: model.exercise,
        num: model.num,
        date: new Date(),
      });
      model.id = logRef.id;
      return model;
    }
    return false;
  }

  static async beforeDelete(model) {
    const user = User.getters("currentUser");

    if (user && model.id.length > 5) {
      deleteDoc(doc(db, `users/${user.id}/logs`, model.id));
    }
  }
}
