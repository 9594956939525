<template>
  <v-app id="inspire">
    <nav-bar></nav-bar>
    <!-- <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Application</v-toolbar-title>
    </v-app-bar> -->
    <v-main v-if="isActive || !user" class="pt-14 overflow-y-auto">
      <router-view />
      <new-content-available />
    </v-main>
    <v-main v-else class="pt-14 overflow-y-auto">
      <v-container class="fill-height" fluid>
        <div class="d-flex flex-column align-center justify-center mx-auto">
          <div class="red--text text-h5">ACCESS DENIED!</div>
          <div>ask for access..</div>
        </div>
      </v-container>
    </v-main>

    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal>
  </v-app>
</template>
<script>
import NavBar from "@/components/NavBar";
// import NewContentAvailableToastr from "@/components/NewContentAvailableToastr";
import NewContentAvailable from "@/components/app/NewContentAvailable";
import AppleAddToHomeScreenModal from "@/components/AppleAddToHomeScreenModal";
import { mapState } from "vuex";
import User from "@/models/User";

export default {
  components: {
    NavBar,
    // NewContentAvailableToastr,
    AppleAddToHomeScreenModal,
    NewContentAvailable,
  },
  computed: {
    // ...mapGetters('app', ['newContentAvailable']),
    ...mapState("app", ["showAddToHomeScreenModalForApple"]),
    isActive() {
      return this.user && (this.user.active || this.user.isAdmin);
    },
    user() {
      return User.getters("currentUser");
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap");
// font-family: 'Comfortaa', cursive m !important;
// $body-font-family: 'Comfortaa';
$title-font: "Comfortaa";

// .v-application {
//   // font-family: $body-font-family, sans-serif !important;
// }

#inspire {
  background-color: #0a0a0a;
  // background: radial-gradient(#252424, #0a0a0a);
  // background: radial-gradient(ellipse at top, #201e1efa, #0a0a0a);
  // background: radial-gradient(ellipse at bottom, #201e1efa, #0a0a0a);
  // radial-gradient(ellipse at bottom, #4d9f0c, transparent);
}
</style>
