import { Model } from "@vuex-orm/core";
import { db } from "@/firebase/init";
import {
  doc,
  collection,
  // getDoc,
  getDocs,
  // Timestamp,
  // serverTimestamp,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

export default class Exercise extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = "exercises";

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.uid(),
      order: this.attr(""),
      day: this.attr(""),
      level: this.attr(""),
      title: this.attr(""),
      name: this.attr(""),
      img_url: this.attr(""),
      duration: this.attr({}),
      linkToNext: this.boolean(false),
      reps: this.attr(""),
      rest: this.attr(""),
      max: this.attr(""),
      finalRest: this.attr(""),
    };
  }

  static async load() {
    let data = [];

    const resultSnapshot = await getDocs(collection(db, this.entity));
    resultSnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    await this.insert({ data: data });
  }

  static async beforeUpdate(model) {
    const exRef = doc(db, this.entity, model.id);
    if (!exRef) return false;
    // console.log(">>>>   model.$toJson()", model.$toJson());
    return setDoc(exRef, model.$toJson(), { merge: true });
  }

  // static async beforeCreate(model) {
  //   if (model.id.length === 5) {
  //     let data = model.$toJson();
  //     delete data.id;
  //     delete data.$id;
  //     const exRef = await addDoc(collection(db, this.entity), data);
  //     model.$id = exRef.id;
  //     return model;
  //   }
  //   return false;
  // }

  static async add(data) {
    const exRef = await addDoc(collection(db, this.entity), data);
    return this.insert({ data: { id: exRef.id, ...data } });
  }

  static async beforeDelete(model) {
    if (model.id.length > 5) {
      deleteDoc(doc(db, this.entity, model.id));
    }
  }
}

export const ExerciseExtra = {
  getters: {
    todaysExercises: () => (level, day) => {
      const dayExercises = Exercise.query()
        .where("day", day)
        .where("level", level)
        .orderBy("order")
        .get();

      const exercises = [];
      let num = 1;
      dayExercises.map((exercise) => {
        if (exercise.linkToNext) {
          console.log(">>>>   link 2 next");
        } else if (exercise.reps) {
          for (let i = 0; i < exercise.reps; i += 1) {
            exercises.push({
              num,
              ...exercise,
              rest:
                i + 1 === exercise.reps ? exercise.finalRest : exercise.rest,
            });
            num += 1;
          }
        } else {
          exercises.push({
            num,
            ...exercise,
            reps: "x",
            rest: exercise.finalRest,
          });
          num += 1;
        }
        return exercise;
      });

      return exercises;
    },
  },
};
